<template>
  <section class="privacy" data-nosnippet>
    <div class="privacy-container">
      <div>
        <router-link class="router-link" to="/">
            <div class="footer-logo-container">
            <div class="footer-brand-image"></div>
          </div>
        </router-link>
      </div>
      <div class="privacy-content">
        <div class="privacy-title title-text highlighted-text">Datenschutzerklärung</div>

        <p><strong>Verantwortlicher f&uuml;r die Datenverarbeitung ist:</strong><br /> 
        Pleasmile UG (haftungsbeschränkt)<br /> 
        Steinring 56<br /> 
        44789 Bochum<br /> <br /> 
        info@pleasmile.com</p> 
        <p>Wir freuen uns über Ihr Interesse an unserem Online-Shop. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.<br></p> 
        <h2>1.&nbsp;Zugriffsdaten und Hosting</h2> 
        <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert. Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.</p> 
        <h3>1.1&nbsp;Hosting</h3> 
        <p>Die Dienste zum Hosting und zur Darstellung der Webseite werden teilweise durch unsere Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag erbracht. Soweit im Rahmen der vorliegenden Datenschutzerklärung nichts anderes erläutert wird, werden alle Zugriffsdaten sowie alle Daten, die in dafür vorgesehenen Formularen auf dieser Webseite erhoben werden, auf ihren Servern verarbeitet. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.</p> 
        <h3>1.2&nbsp;Content Delivery Network</h3> 
        <p>Zum Zwecke einer kürzeren Ladezeit setzen wir bei einigen Angeboten ein sogenanntes Content Delivery Network („CDN“) ein. Bei diesem Dienst werden Inhalte, z.B. große Mediendateien, über regional verteilte Server externer CDN-Dienstleister ausgeliefert. Daher werden auf den Servern der Dienstleister Zugriffsdaten verarbeitet. Unsere Dienstleister sind im Rahmen einer Auftragsverarbeitung für uns tätig. Unsere Dienstleister sitzen und/oder verwenden Server in L&auml;ndern au&szlig;erhalb der EU und des EWR. F&uuml;r diese L&auml;nder liegt kein Angemessenheitsbeschluss der Europ&auml;ischen Kommission vor. Unsere Zusammenarbeit mit ihnen st&uuml;tzt sich auf Standarddatenschutzklauseln der Europ&auml;ischen Kommission.&nbsp; Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.</p>  
        <h2>2. Cookies</h2>
        <h3>2.1 Sitzungs-Cookies/Session-Cookies</h3>
        <p>Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. &nbsp;</p>
        <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</p>
        <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
        <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
        <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
        <h3>2.2 Drittanbieter-Cookies</h3>
        <p>Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
        <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
        <h3>2.3 Beseitigungsmöglichkeit</h3>
        <p>Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.</p>
        <p>Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>

        <h2>3.&nbsp;Datenverarbeitung zur Vertragsabwicklung und zur Kontaktaufnahme </h2> 
        <h3>3.1&nbsp;Datenverarbeitung zur Vertragsabwicklung </h3> 
        <p>Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung, bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren Angabe die Bestellung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich.<br> Wir verwenden die von ihnen mitgeteilten Daten zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen (inkl. Anfragen zu und Abwicklung von ggf. bestehenden Gewährleistungs- und Leistungsstörungsansprüchen sowie etwaiger gesetzlicher Aktualisierungspflichten) gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Weitere Informationen zu der Verarbeitung Ihrer Daten, insbesondere zu der Weitergabe an unsere Dienstleister zum Zwecke der Bestellungs-, Zahlungs- und Versandabwicklung, finden Sie in den nachfolgenden Abschnitten dieser Datenschutzerklärung. Nach vollständiger Abwicklung des Vertrages werden Ihre Daten für die weitere Verarbeitung eingeschränkt und nach Ablauf etwaiger steuer- und handelsrechtlichen Aufbewahrungsfristen gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.</p>  
        <p><strong>Warenwirtschaftssystem</strong></p>   <p>Zur Bestell- und Vertragsabwicklung setzen wir Warenwirtschaftssysteme externer Dienstleister ein. Unsere Dienstleister sind im Rahmen einer Auftragsverarbeitung für uns tätig. Bei Fragen zu unseren Dienstleistern und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit. </p>  
        <h3>3.2&nbsp;Kontaktaufnahme </h3>  <p>Im Rahmen der Kundenkommunikation erheben wir zur Bearbeitung Ihrer Anfragen gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO personenbezogene Daten, wenn Sie uns diese bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Bearbeitung Ihrer Kontaktaufnahme benötigen. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Nach vollständiger Bearbeitung Ihrer Anfrage werden Ihre Daten gelöscht, sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.</p>  
        <h2>4.&nbsp;Datenverarbeitung zum Zwecke der Versandabwicklung</h2> 
        <p>Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir Ihre Daten an den mit der Lieferung beauftragten Versanddienstleister weiter, soweit dies zur Lieferung bestellter Waren erforderlich ist.<br></p>  
        <h2>5.&nbsp;Datenverarbeitung zur Zahlungsabwicklung </h2>  <p>Bei der Abwicklung von Zahlungen in unserem Online-Shop arbeiten wir mit diesen Partnern zusammen: technische Dienstleister, Kreditinstitute, Zahlungsdienstleister.</p>  
        <h3>5.1&nbsp;Datenverarbeitung zur Transaktionsabwicklung </h3>  
        <p>Je nach ausgewählter Zahlungsart geben wir die für die Abwicklung der Zahlungstransaktion notwendigen Daten an unsere technischen Dienstleister, die im Rahmen einer Auftragsverarbeitung für uns tätig sind, oder an die beauftragten Kreditinstitute oder an den ausgewählten Zahlungsdienstleister weiter, soweit dies zur Abwicklung der Zahlung erforderlich ist. Dies dient der Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Zum Teil erheben die Zahlungsdienstleister die für die Abwicklung der Zahlung erforderlichen Daten selbst, z.B. auf ihrer eigenen Webseite oder über eine technische Einbindung im Bestellprozess. Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.<br> Bei Fragen zu unseren Partnern für die Zahlungsabwicklung und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte an die in dieser Datenschutzerklärung beschriebenen Kontaktmöglichkeit.</p>  
        <h3>5.2&nbsp;Datenverarbeitung zum Zwecke der Betrugsprävention und der Optimierung unserer Zahlungsprozesse </h3>  
        <p>Gegebenenfalls geben wir unseren Dienstleistern weitere Daten, die sie zusammen mit den für die Abwicklung der Zahlung notwendigen Daten als unsere Auftragsverarbeiter zum Zwecke der Betrugsprävention und der Optimierung unserer Zahlungsprozesse (z.B. Rechnungsstellung, Abwicklung von angefochtenen Zahlungen, Unterstützung der Buchhaltung) verwenden. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an unserer Absicherung gegen Betrug bzw. an einem effizienten Zahlungsmanagement.</p>  
        <h3>6. Benachrichtigungsfunktion</h3>
        <p>Sofern eines unserer Produkte mal nicht vorrätig ist, haben Sie die Möglichkeit, sich für dieses Produkt einen Reminder einzurichten. Dafür richten wir auf der Produktseite ein Textfeld ein, in das Sie Ihre E-Mail-Adresse eintragen könne. Sobald das konkrete Produkt wieder lieferbar ist, senden wir Ihnen eine E-Mail mit einem entsprechenden Hinweis an die angegebene E-Mail-Adresse. Diese Datenverarbeitung ist zur Vertragsdurchführung bzw. Vertragserfüllung erforderlich (Art. 6 Abs. 1 b DS-GVO). Ihre E-Mail-Adresse wird nach Versendung der Reminder-E-Mail gelöscht.</p>
        <h2>7.&nbsp;Analyse-Tools</h2> 
        <h3>7.1&nbsp;Facebook Pixel</h3>  
        <p>Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von Facebook. Anbieter dieses Dienstes ist die Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittländer übertragen.</p>
        <p>So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet wurden. Dadurch können die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.</p>
        <p>Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir können keine Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden aber von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist und Facebook die Daten für eigene Werbezwecke, entsprechend der Facebook-Datenverwendungsrichtlinie verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie außerhalb von Facebook ermöglichen. Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst werden.</p>
        <p>Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an effektiven Werbemaßnahmen unter Einschluss der sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
        <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: <a class="external-link" target="_blank" title="https://www.facebook.com/legal/EU_data_transfer_addendum" href="https://www.facebook.com/legal/EU_data_transfer_addendum">https://www.facebook.com/legal/EU_data_transfer_addendum</a> und <a class="external-link" target="_blank" title="https://de-de.facebook.com/help/566994660333381" href="https://de-de.facebook.com/help/566994660333381">https://de-de.facebook.com/help/566994660333381</a>.</p>
        <p>In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer Privatsphäre: <a class="external-link" target="_blank" title="https://de-de.facebook.com/about/privacy/" href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a>.</p>
        <p>Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im Bereich Einstellungen für Werbeanzeigen unter <a class="external-link" target="_blank" title="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.</p>
        <h2>8.&nbsp;Kontaktmöglichkeiten und Ihre Rechte </h2> 
        <h3>8.1&nbsp;Ihre Rechte</h3>  
        <p>Als Betroffener haben Sie folgende Rechte:</p> 
        <ul> 
          <li>gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;</li> 
          <li>gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li> 
          <li>gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung 
            <ul> 
              <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li> 
              <li>zur Erfüllung einer rechtlichen Verpflichtung;</li> 
              <li>aus Gründen des öffentlichen Interesses oder</li> 
              <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li> 
              </ul> 
              </li> 
              <li>gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit 
                <ul> 
                  <li>die Richtigkeit der Daten von Ihnen bestritten wird;</li> 
                  <li>die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;</li> 
                  <li>wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder</li> 
                  <li>Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li> 
                  </ul> 
                  </li> 
                  <li>gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li> 
                  <li>gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.</li> 
                  </ul>   
                  <p><strong>Widerspruchsrecht</strong></p> 
                  <p>Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen. Erfolgt die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus Ihrer besonderen Situation ergeben, zu.</p> 
                  <p>Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.</p> 
                  <p>Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.</p> 
                  <h3>7.2&nbsp;Kontaktm&ouml;glichkeiten</h3>  
                  <p>Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.</p>  <br>
                  <p>Diese Datenschutzerklärung basiert auf dem <a class="external-link" target="_blank" title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" class="external-link" href="https://foehlisch.com" rel="nofollow">FÖHLISCH Rechtsanwälte</a>. Es wurden individuelle Anpassungen durch Pleasmile vorgenommen.</p>

      </div>
      <Footer />
    </div>
  </section>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "DataPrivacy",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/colors.scss";

.privacy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $page-background-color-primary-dark;
}

.privacy-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.privacy-content {
  font-family: Dosis;
  font-weight: 400;
  font-size: 18px;
  color: $text-color-primary-light;
  max-width: 600px;
  margin: 40px 40px 40px 40px;
}

.privacy-title {
  display: inline-block;
  margin-bottom: 40px;
}
</style>
